import React from "react";
import "../App.css";
import Card from "../components/Card";
import "./Projects.css";
import {
  cProgrammingLogo,
  cPlusPlusLogo,
  cmakeLogo,
  wxWidgetsLogo,
  javascriptLogo,
  swiftLogo,
  gitIcon,
  reactLogo,
  htmlLogo,
  cssLogo,
  mongoDB,
  nginx,
} from "../helpers/icons";

const cardVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0 },
};

const Projects = () => {
  return (
    <>
      <div className="project-page">
        <div className="cards-container">
          <Card
            title="Stop-motion animator"
            descrip="A simple app for making stop-motion animations"
            githubLink="https://github.com/benITo47/gfk-stop-motion"
            imageSrc="assets/Stop-motion-proj-picture.png"
            stackIcons={[
              { src: cPlusPlusLogo },
              { src: gitIcon },
              { src: wxWidgetsLogo },
              { src: cmakeLogo },
            ]}
          />
          <Card
            title="Personal web-page"
            descrip="Personal, self built and self hosted website"
            githubLink="https://github.com/"
            imageSrc="assets/website-screen.png"
            stackIcons={[
              { src: javascriptLogo },
              { src: reactLogo },
              { src: cssLogo },
              { src: htmlLogo },
            ]}
          />
          <Card
            title="Personal blog"
            descrip="Self built blog, with authentication, user profiles, post editor and comment system"
            githubLink="www.blog.benito.dev"
            imageSrc="assets/blog-screen.png"
            stackIcons={[
              { src: javascriptLogo },
              { src: reactLogo },
              { src: cssLogo },
              { src: htmlLogo },
              { src: mongoDB },
              { src: nginx },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default Projects;
